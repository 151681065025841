<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm bcs-user-info-card" :isTitle="false">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 bcs-user-info">
          근로자 : (만 {{survey.age}}세 {{survey.sexName}}) {{survey.deptName}} / {{survey.userName}}<br/>
          조사일 : {{survey.surveyDate}}
          <!-- surveyDate -->
        </div>
      </template>
    </c-card>
    <c-card class="cardClassDetailForm" title="검토의견">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="survey"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveSurvey"
            @btnCallback="saveSurveyCallback" />
          <c-btn 
            v-show="editable&&!disabled&&isOld" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="survey"
            mappingType="PUT"
            label="사후관리" 
            icon="check"
            @beforeAction="completeSurvey"
            @btnCallback="completeSurveyCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-select
            required
            :disabled="disabled"
            :editable="editable"
            type="edit"
            codeGroupCd="GENERAL_OPINION_CD"
            itemText="codeName"
            itemValue="code"
            label="종합의견 결과"
            name="generalOpinionCd"
            v-model="survey.suitEval.generalOpinionCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-field
            required
            :disabled="disabled"
            :editable="editable"
            :data="survey"
            deptValue="deptCd"
            type="dept_user"
            label="보건담당자"
            name="heaManagerId"
            v-model="survey.suitEval.heaManagerId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="수행업무"
            name="tasksPerformed"
            v-model="survey.suitEval.tasksPerformed"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="생활습관 / 검사항목 검토의견"
            name="habitsReview"
            v-model="survey.suitEval.habitsReview"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="임상증상 / 동반질환 검토의견"
            name="diseaseReview"
            v-model="survey.suitEval.diseaseReview"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="업무적요인 검토의견"
            name="businessFactorsReview"
            v-model="survey.suitEval.businessFactorsReview"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <c-textarea
            :disabled="disabled"
            :editable="editable"
            label="종합의견"
            name="generalOpinion"
            v-model="survey.suitEval.generalOpinion"
          />
        </div>
      </template>
    </c-card>
    <c-table
      class="q-mt-sm brain-cardiovascular-survey"
      title="증사조사표"
      :columns="grid.columns"
      :data="gridData"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      isDense
      :isTitle="false"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00005')}}
            </span>
            <div class="etc-info">
              <template v-if="bmi">
                <q-chip color="teal" text-color="white" icon="info" outline square>
                  {{bmi.riskFactors}}
                </q-chip>
                : {{bmi.riskFactorsResult}}
              </template>
              <template v-if="eGFR">
                <q-chip color="teal" text-color="white" icon="info" outline square>
                  {{eGFR.riskFactors}}
                </q-chip>
                : {{eGFR.riskFactorsResult}}
              </template>
            </div>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000001')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000002')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000004')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000014')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000013')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000031')"
            :editable="false"
            :colspan="2"
            type="number"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===2" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000005')"
            :editable="false"
            :colspan="4"
            codeGroupCd="PHYSICALLY_ACTIVE_OPTION_CD"
            type="radio"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000006')"
            :editable="false"
            :colspan="4"
            codeGroupCd="DRINK_CD"
            type="radio"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000007')"
            :editable="false"
            :colspan="4"
            codeGroupCd="SMOKE_CD"
            type="radio"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===3" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000008')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000009')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000010')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000011')"
            :editable="false"
            :colspan="2"
            type="number"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000012')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000016')"
            :editable="false"
            :colspan="2"
            type="number"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===4" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00001')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===5" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000017')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000018')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000019')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000020')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000021')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000022')"
            :editable="false"
            :colspan="2"
            trueLabel="복용"
            falseLabel="미복용"
            type="check"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===6" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00010')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===7" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000023')"
            :editable="false"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000024')"
            :editable="false"
            :colspan="6"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===8" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000025')"
            :editable="false"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000026')"
            :editable="false"
            :colspan="6"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===9" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000027')"
            :editable="false"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000028')"
            :editable="false"
            :colspan="6"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===10" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000029')"
            :editable="false"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000030')"
            :editable="false"
            :colspan="6"
            type="text"
          />
        </q-tr>
      </template>
    </c-table>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'brainCardiovascularSuitEval',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',
      }),
    },
    survey: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 종합조사표 일련번호
        heaBrainCardiovascularStepCd: '',  // 진행상태
        userId: '',  // 근로자ID
        userName: '',  // 근로자명
        deptCd: '',  // 부서코드
        deptName: '',  // 부서명
        age: 0,  // 나이
        sexCd: '',  // 성별
        sexName: '',  // 성별
        birthDate: '', // 생년월일
        surveyDate: '',  // 평가일
        waistCircumference: '',  // 위험도_허리둘레
        bmi: '',  // 위험도_체질량 지수
        bloodPressure: '',  // 위험도_혈압
        hypertensionMedication: '',  // 위험도_고혈압 약 복용
        fastingBloodSugar: '',  // 위험도_공복 혈당
        diabetesMedication: '',  // 위험도_당뇨병 약 복용
        totalCholesterol: '',  // 위험도_총콜레스테롤
        egfr: '',  // 위험도_eGFR
        dipstickProteinuria: '',  // 위험도_Dipstick proteinuria 단백뇨
        smoking: '',  // 위험도_흡연상태
        physicallyActiveStatus: '',  // 위험도_신체 활동 여부
        regUserId: '',  //
        chgUserId: '',  //
        results: [], // 유해요인 평가결과 목록
        riskAssessment: {
          heaBrainCardiovascularRiskAssessmentId: '',  // 뇌심혈관계 위험평가 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          adjustedCompositeRelativeRisk: '',  // 조정 복합 상대위험도
          riskDevelopDisease: '',  // 발병위험도(10년)
          avgRiskDevelopDisease: '',  // 평균발병위험도(10년)
          riskOrthodonticDevelopment: '',  // 교정발병위험도(10년)
          reducedRiskDevelopDisease: '',  // 발병위험도 감소치
          cardiovascularAge: '',  // 심뇌혈관 나이
          correctedCardiovascularAge: '',  // 교정 심뇌혈관 나이
          integratedRiskClassificationCriteria: '',  // 통합형 위험도 분류기준
          /** 기타 정보 */
          complexRelativeRisk: '',  // 개인별 복합 상대위험도
          correctionCompositeRelativeRisk: '',  // 개인별 교정 복합 상대위험도
          regUserId: '',  //
          chgUserId: '',  //
        }, // 위험평가
        suitEval: {
          heaBrainCardiovascularWorkSuitEvalId: '',  // 뇌심혈관계 업무적합성 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          userId: '',  // 근로자ID (종합조사표 값)
          userName: '',  // 근로자명 (종합조사표 값)
          deptCd: '',  // 부서코드 (종합조사표 값)
          deptName: '',  // 부서명 (종합조사표 값)
          tasksPerformed: '',  // 수행업무
          diseaseReview: '',  // 임상증상 / 동반질환 검토의견
          habitsReview: '',  // 생활습관 / 검사항목 검토의견
          businessFactorsReview: '',  // 업무적요인 검토의견
          generalOpinionCd: '',  // 종합의견 - 결과
          generalOpinion: '',  // 종합의견 - 적합 이외인 경우 기술
          heaManagerId: '',  // 보건담당자 OR 의사
          regUserId: '',  //
          chgUserId: '',  //
        }, // 업무적합성 평가서
      }),
    },
    evalItems: {
      type: Array,
      default: () => [],
    },
    evalItemClasses: {
      type: Array,
      default: () => [],
    },
    riskPredictions: {
      type: Array,
      default: () => [],
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          { name: '1', field: '1', style: 'width:100px' },
          { name: '2', field: '2', style: 'width:150px' },
          { name: '3', field: '3', style: 'width:100px' },
          { name: '4', field: '4', style: 'width:150px' },
          { name: '5', field: '5', style: 'width:100px' },
          { name: '6', field: '6', style: 'width:150px' },
          { name: '7', field: '7', style: 'width:100px' },
          { name: '8', field: '8', style: 'width:150px' },
          { name: '9', field: '9', style: 'width:150px' },
          { name: '10', field: '10', style: 'width:150px' },
          { name: '11', field: '11', style: 'width:150px' },
          { name: '12', field: '12', style: 'width:150px' },
        ],
        data: [],
      },
      editable: true,
      isSave: true,
      isComplete: true,
      saveUrl: '',
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.isOld && this.survey.heaBrainCardiovascularStepCd !== 'HBCS000010'
    },
    sexAge() {
      return `${this.survey.age} / ${this.survey.sexName}`
    },
    gridData() {
      let data = [];
      for (let idx = 0; idx < 15; idx++) {
        data.push({})
      }
      return data;
    },
    evalItemTd() {
      return () => import(`${'@/pages/hea/bcs/brainCardiovascularInfoItemTd.vue'}`)
    },
    bmi() {
      return this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000003' })
    },
    eGFR() {
      return this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: 'BCWI000015' })
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.bcs.workSuitEval.update.url
      this.completeUrl = transactionConfig.hea.bcs.postProcessing.step.url
      // code setting
      // list setting
    },
    evalItemClass(workSuitEvalItemClassCd) {
      let text = '';
      if (this.evalItemClasses && this.evalItemClasses.length > 0) {
        text = this.$_.find(this.evalItemClasses, { code: workSuitEvalItemClassCd }).codeName
      }
      return text;
    },
    getEvalItem(heaBrainCardiovascularEvalItemId) {
      return this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: heaBrainCardiovascularEvalItemId })
    },
    saveSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.survey.suitEval.regUserId = this.$store.getters.user.userId
              this.survey.suitEval.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSurveyCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaBrainCardiovascularSurveyId', _result.data)
      this.$emit('getDetail')
    },
    completeSurvey() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '사후관리 진행하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.survey.suitEval.regUserId = this.$store.getters.user.userId
              this.survey.suitEval.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
  }
};
</script>
<style lang="sass">
.bcs-user-info-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important
.bcs-user-info
  font-size: 1.2rem
  text-align: center
  font-weight: 600

.brain-cardiovascular-survey
  .th-td-style
    vertical-align: middle !important
  .th-style
    background: #EEEEEE !important
    font-weight: 700
    width: 100%
  .td-style
    width: 16.6%
  .etc-info
    display: inline-block
    float: right
  .review-td
    height: auto !important
</style>